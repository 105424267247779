import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginComponent } from './login/login.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'meistersite';
  
  subscription: Subscription;
  username: string = '';
  
  subscribetologin(componentRef)
  {
	  if(! (componentRef instanceof LoginComponent)){
		  return;
	  }
	  const child: LoginComponent = componentRef;
	  child.loggedin.subscribe(
	  (res)=> this.username = res
	  )
	  
  }
  
  unsubcribelogin()
  {
	  if(this.subscription)
	  {
		  this.subscription.unsubscribe();
	  }
	  
  }
  
}
