
<nav class="navbar navbar-default" style="margin-bottom:0px">
  <div class="container-fluid">
      <div class="container-fluid" id="content-container">
        <ul class="nav navbar-nav">
            <li routerLinkActive="active" class="nav-item"><a routerLink="/main" >Meistercards</a></li>
            <li routerLinkActive="active" class="nav-item"><a routerLink="/opticallmain" [state]=username >Opticall</a></li>
            <li routerLinkActive="active" class="nav-item"><a routerLink="/opticallrules" >Opticall Rules</a></li>
            <li routerLinkActive="active" class="nav-item" *ngIf="username == ''"><a routerLink="/login"  >Login</a></li>
        </ul>
        <div class="extra-links">
          <div>
            <a target="_blank" href="https://www.instagram.com/meistercards/">
              <img src="assets/instagram.png"/>
            </a>
          </div>
        </div>
    </div>
  </div>
</nav>