<div id="main">
    <div id="thetimegame" class="content">
        <div class="">
            <div class="center">
                <h1 class="">The Time Game</h1>
            </div>
            <div class="spacer"></div>
            <div class="center">
                <div class="time-game">
                    <div class="moon-mash-player">
                        <img src="assets/player_1.png" width="25px" height="25px" id="player-1"/>
                        <img src="assets/symbol_moon_mash.png" width="150px" height="150px" id="moon-mash"/>
                    </div>
                    <div class="goat-ahead-container">
                        <img src="assets/symbol_goat_ahead.png" width="150px" height="150px" id="goat-ahead"/>
                    </div>
                </div>
                <div>
                    <a class="btn btn-primary reg-btn" target="_blank" href="https://store.steampowered.com/app/2865190/The_Time_Game/">
                        Wishlist on Steam
                    </a>
                </div>
            </div>
            <div class="spacer"></div>
        </div>
    </div>

    <div id="opticall" class="content">
        <div class="">
            <div class="center">
                <h1 class="">Opticall</h1>
            </div>
            <div class="center" id="cards">
                <img src="assets/opticall_cards.png"/>
            </div>
            <div class="center">
                <div>
                    <a class="btn btn-primary reg-btn" target="_blank" href="https://www.ebay.com/itm/175673896431?hash=item28e6faedef:g:o50AAOSwiZFkMlkR">Buy Now</a>
                </div>
                <div class="spacer"></div>
                <div>
                    <button class="btn btn-default reg-btn" type="submit" routerLink="/opticallrules">Rules</button>
                </div>
            </div>
        </div>
    </div>
    <div id="signup">
        <div class="center">
            <h3 class="">Sign Up for Updates and News!</h3>
        </div>
        <div class="center">
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="form-group">
                <label for="email" class="email-label">Your Email:</label>
                <input class="form-control" id="email" type="email" [(ngModel)]="email" placeholder="Email">
            </div>
            <div class="spacer"></div>
            <button (click)="submitEmail()" class="btn btn-primary reg-btn" id="submitBtn" data-loading-text="Loading...">Subscribe</button>
            <div class="spacer"></div>
            <div class="icon-container fade in center" id="icon-container">
                <span class="glyphicon glyphicon-ok"></span>
                <h5 class="">Success! You are now signed-up to recieve updates and news about Meister Cards' games!</h5>
            </div>
        </div>
    </div>
</div>
