import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-opticallmain',
  templateUrl: './opticallmain.component.html',
  styleUrls: ['./opticallmain.component.css']
})
export class OpticallmainComponent implements OnInit {

username: string
  constructor(private router: Router) {
this.username =  String(this.router.getCurrentNavigation().extras.state);	  
}

  ngOnInit(): void {
	 
  }

}
