import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MeistermainComponent } from './meistermain/meistermain.component';
import { OpticallmainComponent } from './opticallmain/opticallmain.component';
import { OpticallrulesComponent } from './opticallrules/opticallrules.component';
import { LoginComponent } from './login/login.component';
const routes: Routes = [{ path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: MeistermainComponent },
  { path: 'opticallmain', component: OpticallmainComponent },
  { path: 'opticallrules', component: OpticallrulesComponent },
  { path: 'login', component: LoginComponent },];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
