import { Component, OnInit, Output , EventEmitter } from '@angular/core';
import { DbserverService } from '../dbserver.service'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


username: string ;
password: string ; 
loggedin = new EventEmitter<string>();
  constructor(private service: DbserverService,private router: Router) { }

  ngOnInit(): void {
  }
  
 adduser(){
	 //console.log(this.username + this.password);
	 this.service.postuser(this.username,this.password).subscribe(()=>console.log("posted"))
 }
 loginuser()
 {
	 this.service.getuser(this.username).subscribe((res)=>{if(res!= null){if(res['value']==this.password){console.log("success");this.loggedin.emit(this.username);this.addparam('/main');}else{console.log("failed")}}else{console.log("failed")}})
 }

addparam(pagetype: string) {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([pagetype], {replaceUrl: false,
      queryParams: {
        
      },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      
      skipLocationChange: true //trigger navigation
      
    });
  }
}
