import { Component, OnInit, HostListener } from '@angular/core';
import { DbserverService } from '../dbserver.service'
@Component({
  selector: 'app-meistermain',
  templateUrl: './meistermain.component.html',
  styleUrls: ['./meistermain.component.css']
})
export class MeistermainComponent implements OnInit {
	email: string;

  player1: HTMLElement;
  moonMash: HTMLElement;
  goatAhead: HTMLElement;
  id: any;
  coords: {
    startXPos: number;
    startYPos: number;
    endXPos: number;
    endYPos: number;
  }
  isGoingRight: boolean;
  offset: number;
  rotation: number;
  distance: number;

  constructor(private service: DbserverService) { }

  ngOnInit(): void {
    this.player1 = document.getElementById("player-1");
    this.moonMash = document.getElementById("moon-mash");
    this.goatAhead = document.getElementById("goat-ahead");

    const moonMashRect = this.moonMash.getBoundingClientRect();
    const startXPos = moonMashRect.right;
    const startYPos = moonMashRect.bottom - moonMashRect.width / 2;
    const goatAheadRect = this.goatAhead.getBoundingClientRect();
    const endXPos = goatAheadRect.left + 100;
    const endYPos = goatAheadRect.bottom - goatAheadRect.width / 2;
    this.coords = {
      startXPos,
      startYPos,
      endXPos,
      endYPos
    }
    this.isGoingRight = true;
    this.offset = 5;
    this.rotation = 0;
    this.distance = 2;
    this.player1.style.left = `${this.coords.startXPos - this.offset}px`;

    this.id = setInterval(() => {
      const currLeft = this.player1.style.left.split("px")[0];

      if(Number(currLeft) >= endXPos - this.offset) {
        this.isGoingRight = false;
      } else if(Number(currLeft) <= startXPos - this.offset) {
        this.isGoingRight = true;
      }

      if(this.isGoingRight) {
        const nextPos = Number(currLeft) + this.distance;
        this.player1.style.left = `${nextPos}px`;
      } else {
        const nextPos = Number(currLeft) - this.distance;
        this.player1.style.left = `${nextPos}px`;
      }

      this.rotation = this.rotation + 4;
      this.player1.style.transform = `rotate(${this.rotation}deg)`;

    }, 10);
  }
  
	submitEmail()
	{
		this.service.postemail(this.email).subscribe(()=> {
      document.getElementById("icon-container").style.display = "flex";
      console.log("posted");
    })
	}
  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }
}
