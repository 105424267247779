import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

@Input() username;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
  }
addparam(pagetype: string) {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this.router.navigate([pagetype], {replaceUrl: false,
      queryParams: {
        
      },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      
      skipLocationChange: false //trigger navigation
      
    });
  }
}
