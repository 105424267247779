
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DbserverService {

  
username: string = '';
	
  readonly rootURL = "https://meistersite-47ce5-default-rtdb.firebaseio.com"

  constructor(public http: HttpClient) {
    
      
  }
  

  postuser(username: string,password: string) {
	  //console.log(username + password);
    return this.http.put(this.rootURL + '/users' + '/' + username + '.json', {'value':password});
  }
  postemail(email: string) {
	  //console.log(username + password);
    return this.http.put(this.rootURL + '/emails' + '/' + email.split(".")[0] + '.json', {'value':email});
  }
 
  getuser(username: string) {
    return this.http.get(this.rootURL + '/users/' + username +'.json?shallow=false');
  }

 
}
